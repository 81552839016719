.qrCodeGenPageContainer {
  width: 100%;

  min-height: 700px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #f4f6fc;
}

.qrCodeGenPageContainer .qrCodeGenPage {
  width: 100%;
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;

  padding-top: 20px;

  height: 100%;
  min-height: calc(100vh - 60px);

  display: grid;
  grid-template-columns: 1fr 1fr;
}

.qrCodeGenPageContainer .qrCodeGenPage .leftSection {
  /* padding-top: 40px; */
  /* background-color: red; */
  height: 100%;

  display: flex;
  flex-direction: column;

  gap: 30px;
}
.qrCodeGenPageContainer .qrCodeGenPage .leftSection .input {
  max-width: 80%;
}

.qrCodeGenPageContainer .qrCodeGenPage .rightSection {
  padding-top: 10px;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.qrCodeGenPageContainer .qrCodeGenPage .rightSection .QRCode {
  background-color: #fff;
  padding: 10px 10px 6.5px 10px;
  background-color: #fff;

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.25); */

  border-radius: 5px;
}

.qrCodeGenPageContainer .qrCodeGenPage .rightSection button {
  width: 275px;
  height: 50px;

  background: linear-gradient(90deg, #213659 0%, #131f33 100%);
  color: #fff;
  font-weight: 600;
  text-transform: none;
  border-radius: 5px;

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.25); */
}
