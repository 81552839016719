@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html {
  scroll-behavior: smooth;
  scroll-padding-top: 2.5rem;
}

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  font-size: 16px;
}

.navLink {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0px;
  margin: 0px;
  /* font-family: "Roboto", sans-serif; */
}

h2 {
  font-weight: 600;
}
h3 {
  font-weight: 600;
}

p {
  font-weight: 400;
  font-size: 0.95rem;
  line-height: 1.5em;
}

@media print {
  #pager,
  form,
  .no-print {
    display: none !important;
    height: 0;
  }

  .no-print,
  .no-print * {
    display: none !important;
    height: 0;
  }
}
