.feedbackPage {
  width: 100%;
  min-height: 700px;
  height: 100vh;

  background: linear-gradient(90deg, #213659 0%, #131f33 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.feedbackPage .feedbackPageContainer {
  width: 100%;
  min-height: 700px;
  flex: 1;

  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.feedbackPage .feedbackPageContainer .feedbackForm {
  width: 100%;
  max-width: 350px;

  height: 100%;
  min-height: 500px;

  background-color: #fff;

  border-radius: 20px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* #213659
#131F33 */

.feedbackPage .feedbackPageContainer .feedbackForm img {
  height: 60px;
}

.feedbackPage .feedbackPageContainer .feedbackForm h3 {
  font-weight: 500;
  padding-top: 20px;
}

.feedbackPage .feedbackPageContainer .feedbackForm h4 {
  font-weight: 400;
  padding-top: 2.5px;

  font-size: 0.8rem;
  opacity: 0.75;
}

.feedbackPage .feedbackPageContainer .feedbackForm textarea {
  display: flex;
  flex: 1;
  padding: 10px;

  border: none !important;
  border-radius: 5px;

  resize: none;
}

.feedbackPage .feedbackPageContainer .feedbackForm textarea:focus-visible {
  border-color: red !important;
}

.feedbackPage .feedbackPageContainer .feedbackForm > button {
  background: linear-gradient(90deg, #213659 0%, #131f33 100%);
  color: #fff;
}

.LogoText {
  position: absolute;
  bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .LogoText {
    position: absolute !important;
    bottom: 30px;
    /* background-color: lightblue; */
  }

  .feedbackPage .feedbackPageContainer .feedbackPageContainer {
    height: calc(100vh - 50px);
  }
}
